<template>
	<section class="section-wrapper" v-background="value.section_bckgr.path">
		<base-container>
			<client-only>
				<cms-text :value="`${prefix}.section_title`" :props="{variant: 'heading', tag: 'h2', color: 'white' }" class="section-title"/>
				<cms-text :value="`${prefix}.section_desc`" :props="{variant: 'paragraph', tag: 'p', color: 'white' }"/>
			</client-only>
			<div class="squad-tiles">
				<client-only>
					<grid-template cols="5">
							<squad-tile v-for="(tile, index) in value.items" :key="index" :tile="tile"/>
					</grid-template>
				</client-only>
			</div>
		</base-container>
	</section>
</template>
<script>
import GridTemplate from '~/website/front/templates/GridTemplate.vue'
import SquadTile from '../../components/atoms/SquadTile/SquadTile.vue'
import ClientOnly from 'vue-client-only'
export default {
	components: { GridTemplate, SquadTile, ClientOnly },
	props: {
		prefix: String,
		value: Object
	}
}
</script>
<style lang="scss" scoped>
.section-wrapper {
    padding: 4rem 0;
	background-repeat: no-repeat;
	background-size: cover;
    @include media-breakpoint-up(xl) {
        padding: 8rem 0;
    }

	::v-deep strong{
		color: $white;
	}
}

.section-title {
	margin-bottom: 3rem;
	@include media-breakpoint-up(xl) {
        margin-bottom: 6rem;
    }
}

::v-deep .grid-wrapper {
	gap: 2rem;
}

.squad-tiles {
	margin-top: 2rem;

	@include media-breakpoint-up(xl) {
        margin-top: 4rem;
    }

	.container {
		padding: 0;
	}
}
</style>
